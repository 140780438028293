@use "@angular/material" as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(mat.$indigo-palette);
$frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

// /* You can add global styles to this file, and also import other style files */
//dark theme
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./styles-constant/listener-auth-constants.scss";
@import "node_modules/ng-zorro-antd/ng-zorro-antd.min.css";
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  color-scheme: light;
}

html,
body {
  overflow: auto;
  min-height: 100vh;
}

.header-text {
  font-size: $ft-size-header;
  font-weight: $ft-medium;
}

.btn-link {
  color: $clr-text-primary;
}

.custom-btn-primary {
  background: $clr-btn-primary;
  border: none;
  color: $clr-text-tertiary;
  text-align: center;
  font-size: $ft-size-primary;
  cursor: pointer;
}

.border-separator {
  border-right: $border-separator;
}

.no-border {
  border: none;
}

::placeholder {
  color: $clr-text-placeholder;
}

.input-focussed {
  border-color: $clr-border-focussed;
}

.input-error {
  border-color: $clr-border-error;
}

.input-success {
  border-color: $clr-border-success;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}

.otp-box {
  width: 55px;
  /* height: 50px;
    margin: 0 10px; */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  color: #6f6f6f;
  background: white;
}

.otp-box:focus {
  border-color: #007bff;
}

.otplabel {
  text-align: center;
  margin-top: 2rem;
}

.email-label {
  margin-top: 2rem;
}

.container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.otptimer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body {
  flex: 0 0 auto;
}

.card {
  height: 80%;
  max-width: 60rem;
  background-color: white;
}

.card img {
  height: 80%;
  margin-top: 3rem;
  width: 40%;
  margin-left: 2rem;
}

.username {
  margin-bottom: 1rem;
}

.btn-block {
  background: #15154c;
  margin-left: 2rem;
  margin-top: 2rem;
  width: 91%;
  color: $clr-text-tertiary !important;
}

.timer {
  margin-top: 1rem;
  margin-left: 0.2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #15154c;
}

.a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.5px;
  color: #15154c;
  margin-left: 14rem;
  margin-top: 2px;
  justify-content: flex-end;
}

.signup {
  margin-left: 8rem;
  margin-top: 1rem;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}

.nootp {
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 10px;
}

.point {
  cursor: pointer;
}

.mt-4 {
  max-width: 351.91px;
}

.mt-4 img {
  height: 10%;
  width: 40%;
  display: block;
  margin: 0 7rem;
  margin-top: 3rem;
}

.mt-4 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #15154c;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.divider-vertical {
  height: 90%;
  margin: 1rem;
  width: 0%;
  margin-top: 3rem;
  margin-left: 3rem;
  margin-right: 2.5rem;
}

.divider {
  border: 1px solid #aaa9dd;
  display: block;
}

.mt-4 label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #15154c;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 3px;
  margin-left: 2rem;
}

.mt-4 input {
  background-color: white;
  border-color: rgb(232, 232, 232);
  color: #6f6f6f;
  margin-left: 2rem;
  border-radius: 6px;
  font-family: "Poppins";
  letter-spacing: 0.25px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
}

.mt-4 input::placeholder {
  color: rgb(232, 232, 232);
}

.mt-4 .set-pass {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.mt-4 .info {
  height: 15px;
  cursor: pointer;
  display: flex;
  margin: -5px;
  margin-left: 17rem;
}

.inputwithicon {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 2px solid #e8e8e8;
  justify-content: space-between;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}

.inputwithicon .input {
  border: transparent;
  background: transparent;
  margin-left: 0rem;
}

.error {
  border: 1px solid #eb5757;
  box-shadow: 0px 0px 6px rgba(235, 87, 87, 0.32);
}

.valid {
  border: 1px solid #219653;
  box-shadow: 0px 0px 6px rgba(33, 150, 83, 0.27);
}

.inputwithicon input:focus {
  border: transparent;
  outline: none;
  /* This removes the default blue outline */
}

.inputwithicon .img {
  height: 20px;
  margin: 0px;
  cursor: pointer;
}

.card.mini {
  position: absolute;
  height: 20%;
  width: 17%;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 5.7rem;
  margin-top: 8px;
  z-index: 9999;
}

.card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.card li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #6f6f6f;
  margin-left: 0.5rem;
  margin-top: 4px;
}

.pass-require {
  margin: 1rem;
}

.mt-4 button:disabled {
  background-color: gray;
  color: white;
}

@import "./styles-constant/listener-ui-constants.scss";

html,
body {
  // overflow: auto;
  min-height: 100vh;
}

*,
html,
body {
  font-family: $ft-family-primary-listener !important;
}

.input-datepicker {
  border: 1px solid #e8e8e8;
  border-radius: $border-radius-regular-listener !important;
  color: $clr-text-input-listener;
  height: 2.5rem;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  height: auto;
}

thead {
  tr {
    background-color: $background-secondary-listener;

    th {
      font-size: $ft-size-small-listener;
      font-weight: $ft-semi-bold-listener;
    }
  }
}

tbody {
  td {
    font-size: $ft-size-small-listener;
    font-weight: $ft-semi-bold-listener;
  }

  tr:nth-child(even) {
    background-color: $background-tertiary-listener;
  }

  tr {
    &.disabled-row {
      background-color: $clr-text-muted-listener;
    }
  }
}

// .headerlistener {
//   background-color: $background-primary-listener;
//   font-size: $ft-size-regular-listener;
//   font-weight: $ft-medium-listener;
//   box-shadow: none;
//   padding: 0;
//   padding-left: 10px;
// }

.headerlistener-text {
  font-size: $ft-size-large-listener;
  font-weight: $ft-bold-listener;
  color: $clr-text-header-listener;
  white-space: nowrap;
}

.sub-headerlistener-text {
  font-size: $ft-size-medium-listener;
  font-weight: $ft-medium-listener;
  color: $clr-text-sub-header-listener;
}

.sub-text {
  color: #707eae;
  font-size: $ft-size-small-listener;
  font-weight: $ft-normal-listener;
}

.contentlistener {
  background-color: $background-secondary-listener;
}

.menu {
  background-color: $background-primary-listener !important;

  .selected-item {
    background-color: $background-secondary-listener !important;
  }
}

.menu-item:active {
  background-color: $background-primary-listener;
}

.menu-item:hover {
  background-color: transparent;
}

.menu-item {
  color: $clr-text-primary-listener;
}

.listenercontainer {
  height: 100vh;
  max-width: 100% !important;
}

.custom-card {
  background: $clr-bg-primary-listener;
  border-radius: $border-radius-xl-listener;
  width: full;
  height: auto;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  background-color: none;
}

.btn-link-listener {
  color: $clr-text-primary-listener;
}

.btn-block-listener {
  width: 15%;
  margin: 5px;
  border: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  border-radius: 6px;
  background: $clr-text-secondary-listener;
}

.info-section {
  background-color: $clr-text-secondary-listener;
  border-radius: $border-radius-regular-listener;
  color: $clr-text-quarternary-listener;
}

.large-text {
  font-size: $ft-size-large-listener;
}

.xs-text {
  font-size: $ft-size-xs-listener;
}

.custom-btn-primary-listener {
  background: $clr-btn-primary-listener;
  border: none;
  color: $clr-text-quarternary-listener;
  text-align: center;
  font-size: $ft-size-regular-listener;
  cursor: pointer;
  border-radius: $border-radius-regular-listener;
}
.btn-color {
  background: $clr-btn-primary-listener;
  color: $clr-text-quarternary-listener;
  border: none;
}

.btn-color:hover {
  background: $clr-btn-primary-listener;
  color: $clr-text-quarternary-listener;
  border: none;
}

.btn-color:focus {
  background: $clr-btn-primary-listener;
  color: $clr-text-quarternary-listener;
  border: none;
}

.instruction {
  font-size: $ft-size-regular-listener;
  font-weight: $ft-medium-listener;
  color: $clr-text-primary-listener;

  &.small {
    font-size: $ft-size-small-listener;
  }

  &.light {
    color: $clr-text-secondary-listener;
  }

  &.center {
    text-align: center;
  }
}

.vertical-separator {
  border-right: $border-separator-listener;
}

.horizontal-separator {
  border-bottom: $border-separator-listener;
}

.no-border {
  border: none;
}

::placeholder {
  color: $clr-text-placeholder-listener;
  font-size: $ft-size-regular-listener;
  font-weight: $ft-light-listener;
}

.input-listener {
  border: 1px solid #e8e8e8;
  border-radius: $border-radius-regular-listener !important;
  color: $clr-text-input-listener;
  // max-width: 100%;
  height: 2.5rem;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;

  &.small-text {
    font-size: $ft-size-small-listener;
  }

  &.center {
    text-align: center;
  }

  background-color: $background-primary-listener;
}

.input-listener:-internal-autofill-selected {
  background-color: $background-primary-listener !important;
  color: $clr-text-input-listener !important;
}

:root {
  color-scheme: light;
}

.select {
  border: $border-secondary-listener;
  max-width: 100%;
  border-radius: $border-radius-regular-listener !important;
  color: $clr-text-input-listener;

  &.small-text {
    font-size: $ft-size-small-listener;
  }

  &.center {
    text-align: center;
  }
}

.textarea {
  background-color: $background-primary-listener;
  border: $border-secondary-listener;
  border-radius: $border-radius-regular-listener !important;
  color: $clr-text-input-listener;
}

.clickable {
  cursor: pointer;
}

.divider {
  border: $border-divider-listener;
  height: 0px;
}

.divider-horizontal:after,
.divider-horizontal:before,
.divider:after,
.divider:before {
  background-color: transparent;
}

.focussed:focus {
  border-color: $clr-border-focussed-listener !important;
}

.input-listener-focussed {
  border-color: $clr-border-focussed-listener;
}

.input-listener-error {
  border: 1px solid $clr-border-error-listener !important;
  box-shadow: 0px 0px 6px rgba(235, 87, 87, 0.32);
}

.input-listener-success {
  border-color: $clr-border-success-listener;
  box-shadow: 0px 0px 6px rgba(33, 150, 83, 0.27);
}

.form-label {
  font-size: $ft-size-small-listener;
  font-weight: $ft-medium-listener;
  color: $clr-text-secondary-listener;
  justify-content: start;
  gap: 5px;

  &.additional-label {
    color: $clr-text-input-listener;
  }

  &.required:after {
    content: "*";
    color: $clr-text-error-listener;
  }
}

.text-q {
  color: $clr-text-quarternary-listener;
}

.inputwithiconlistener {
  display: flex;
  align-items: center;
  border-radius: $border-radius-medium-listener;
  justify-content: space-between;
  margin-left: 2rem;
  background-color: $background-primary-listener;
}

.inputwithiconlistener .input-listener {
  border: transparent;
  margin-left: 0rem;
  background-color: $background-primary-listener;
  margin: 2px;
  color: $clr-text-tertiary-listener;
}

.pass-require {
  margin: 1rem;
}

.card .mini {
  position: absolute;
  height: 20%;
  width: 17%;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-large-listener;
  margin-left: 5.7rem;
  margin-top: 8px;
  z-index: 9999;
}

.card p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.card li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #6f6f6f;
  margin-left: 0.5rem;
  margin-top: 4px;
}

.noborder {
  border: transparent;
}

.dropdown-menu {
  background: #ffffff;
}

.btn-ghost:hover {
  background: transparent;
}

.dropdown-item:hover {
  background: $clr-btn-primary-listener;
  color: #ffffff;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.alert-card {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  border-radius: 0.75rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 24rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 13px 4px #00000029;
  position: absolute;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert-card-body {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 2rem;
}

.alert-card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.75rem;
}

.btn-block-common {
  background: #15154c;
  width: 100%;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-container.inline {
  position: relative;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.ant-switch {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ),
    linear-gradient(to right, #fff, #fff) !important;
  color: rgba(0, 0, 0, 0.85);
}

.ant-switch-checked {
  background: #1890ff !important;
  background-image: none;
}
