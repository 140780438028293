$background-primary-listener: #ffffff;
$background-secondary-listener: #eff3fd;
$background-tertiary-listener: #f3f3ff;

$clr-text-primary-listener: #192a3e;
$clr-text-secondary-listener: #15154c;
$clr-text-tertiary-listener: #333333;
$clr-text-quarternary-listener: #ffffff;
$clr-text-header-listener: #00140e;
$clr-text-sub-header-listener: #5b5f7b;
$clr-text-input-listener: #6f6f6f;

$clr-text-muted-listener: #e8e8e8;
$clr-text-placeholder-listener: #d2d2d2;
$clr-text-error-listener: #eb5757;

$clr-bg-primary-listener: #ffffff;
$clr-bg-secondary-listener: linear-gradient(#aba8dd, #55dfe0);

$border-separator-listener: 1px solid #e4e7ed;

$clr-border-focussed-listener: #5a55d2;
$clr-border-success-listener: #219653;
$clr-border-error-listener: #eb5757;
$clr-border-primary-listener: #e8e8e8;
$clr-border-secondary-listener: #c4c4c4;

$border-primary-listener: 1px solid $clr-text-primary-listener;
$border-secondary-listener: 1px solid $clr-border-secondary-listener;
$border-divider-listener: 1px solid $background-secondary-listener;

$border-radius-regular-listener: 6px;
$border-radius-medium-listener: 8px;
$border-radius-large-listener: 10px;
$border-radius-xl-listener: 16px;

$clr-btn-primary-listener: #15154c;
$clr-btn-secondary-listener: #15154c; // TBD
$clr-btn-cancel-listener: #e8e8e8; // TBD
$clr-btn-disabled-listener: #e8e8e8; // TBD

// define fonts
$ft-family-primary-listener: "Poppins";
$ft-family-secondary-listener: "Poppins"; // TBD

$ft-thin-listener: 100;
$ft-extralight-listener: 200;
$ft-light-listener: 300;
$ft-normal-listener: 400;
$ft-medium-listener: 500;
$ft-semi-bold-listener: 600;
$ft-bold-listener: 700;
$ft-extrabold-listener: 800;

$ft-size-regular-listener: 14px;
$ft-size-small-listener: 12px;
$ft-size-xs-listener: 10px;
$ft-size-large-listener: 21px;
$ft-size-medium-listener: 18px;
